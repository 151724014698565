<template>
  <v-app>
    <div class="container">
      <h1>How-To</h1>
      <br>
      <br>
      <v-card
        class="mx-auto"
        max-width="900"
      >
        <v-card-text>
          <div class="aboutContent">
            <br>
            <h2>Using heedi</h2>
            <hr>
            <br>
            <p><b>Searching for Routines</b></p>
            <p>heedi has a growing list of routines that can be searched from the top of the home page, or from any screen via the search icon <i class="fas fa-search"></i> located in the top navigation bar.    </p>
            <div class="searchContainer">
              <RoutineSearch />
            </div>
            <br>
            <br>
            <p><b>Main Console</b></p>
            <p>The Main Console is your primary heedi view.</p>

            <div class="flexContainer">
                <div class="heediImgHolder">
                  <img :src="require('@/assets/heedi_support.png')" alt="heedi Support" id="heediSupportImg">
                </div>
                <div>
                  <ul style="list-style: none;">
                    <li>
                      <p><b><span style="font-size: 1.4em">A</span>:  Heedar</b></p>
                      <p>Next three moments are displayed.  Click on the squares to reveal the outcome form with the Did It button.  When you complete a task, you'll let heedi know by completing the Outcome form and clicking on Did It.</p>
                    </li>
                    <li>
                      <p><b><span style="font-size: 1.4em">B</span>:  Insighter</b></p>
                      <p>This area presents the key insights for the <b>very next item</b> on your agenda item list.  After you accumulate a few days outcome data, you will see a chevron arrow that when clicked will reveal more detail in charts and graphs.</p>
                    </li>
                    <li>
                      <p><b><span style="font-size: 1.4em">C</span>:  Active Routines</b></p>
                      <p>All the routines you follow are displayed here.  You can delete routines when you have more than one.  You must always be subscribed to at least one routine.   Clicking on the routine chips will reveal details - coming soon.  </p>
                    </li>
                    <li>
                      <p><b><span style="font-size: 1.4em">D</span>:  Day Plan</b></p>
                      <p>Your day plan is based on the routines you follow.  There are no schedules or set times.  The Day Plan is a punch list of activities for you to do in the day.  Doing so keeps you on track.</p>
                    </li>
                  </ul>
                  
                </div>
            </div>
            <br>
            <br>
            <br>
            
            <h2>Tips for using heedi:</h2>
            <hr>
            <br>
            <p><b>Do not expect yourself to complete everything</b></p>
            <p>If you start to fall off pace with heedi, heedi is not broke, and you are not broke, all this is normal.  It is very challenging to maintain 100% 'Did It' scores on heedi - that's what makes this fun.  And when you are killing it, you should feel satisfaction knowing you are doing something difficult.  Just remember, heedi is just a tool.  YOU still have to do everything.  There will be weeks where you will likely not use heedi.  Then, suddenly, you'll go many weeks - that's okay too.  We ebb and flow.  This is where heedi shines.  When you come back to heedi, it's always right there at the very next step ready to bring you back in.</p>  
            
            <br>
            <br> 
            <br>
            <br>
            <br>
            
            <h2>Questions and Answers</h2>
            <hr>
            <br>
            <p><b>Why can't I change the routine; set my own schedule?</b></p>
            <p>A goal of heedi is to be a reliable source of 'proven' next action steps that will lead you towards what you want.   For us to collectively find that best pathway forward, we must have a similar journey so that everyone's results and feedback come from a shared experience.  The idea is that if we sync on our human-action routines, we create a scientific-basis for improving our mutually-shared routines, and ultimately improve our wellbeing.</p>  
            <p>If you cannot find a routine you want to follow, you can request we <router-link to="/routine/request/">create a new routine.</router-link></p>
            <br>
            <br>
            <br>
            <hr>
            <br>
            <br>
            <br>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size: 1.4em;"><a href= "mailto:support@heedi.com"><b>Contact heedi Support</b></a></span></p>
            <br>
            <br>
            
            
            
            <!--
            <p><b>Here is another question?</b></p>
            <p>text</p>
            <br>
            <br>
            -->
            <br>
            <br>
            <br>
            <br>

            

          </div>
        </v-card-text>
      </v-card>
      <br>
      <br>
      <br>
      <!--
      <div class="searchContainer">
        <RoutineSearch />
      </div>
      -->
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <VueFooter :dark-text="true" />
    </div>
  </v-app>

    <!--
    <div class="justify-content-between row">
      <NestedDraggable2 class="col-8" v-model="elements" />
      <br />
      <br />
      <RawDisplayer class="col-4" :title="'Vuex Store'" :value="elements" />
    </div>
    -->


    <!--
    <h3>About page</h3>
    <h4>Research that shaped this app:</h4>
    <h4>No challenges, leader boards, points or likes.</h4>
    <h4>Building a taxonomy for human purpose</h4>
    <br />
    <br />
    <br />-->


   <!--
   <input type="file" id="file" name="file"/>
   -->
<!--
    <div class="blockWrapper">
      <div class="statement mean">
        <span style="color:red;">heedi</span> wants to help you pay more attention to yourself
      </div>
      <div class="statement end">
        Doing so can make you less distracted and happier
      </div>
      <div class="statement end">
        Not doing so can put you behind where you want to be
      </div>
      <div class="statement">
        Start a purpose <router-link :to="`/evolve/start-purpose`"><a href="#">now.</a></router-link>
      </div>

      <div class="moreLink" @click="showBlock2 = !showBlock2" v-if="!showBlock2">
        <div v-if="!showBlock2">
          more&nbsp;<span class="downIcon"><i class="fas fa-chevron-circle-down"></i></span>
        </div>
      </div>
    </div>

    
    <div class="blockWrapper" v-if="showBlock2">
      <div class="statement mean">
        For individuals, <span style="color:red;">heedi</span> is a metacognition tool.  For the collective society, <span style="color:red;">heedi</span> is a human purpose data wrangling system meant to aggregate and structure human purpose data.
      </div>
      <div class="statement end">
        It can help you do multiple things at once
      </div>
      <div class="statement end">
        This is important because time is the true scarcity of life, and augmenting our ability to pay attention will be one way to evolve
      </div>
      <div class="statement">
        And thrive 
      </div>

      <div class="moreLink" @click="showBlock3 = !showBlock3"  v-if="!showBlock3">
        <div v-if="!showBlock3">
          more&nbsp;<span class="downIcon"><i class="fas fa-chevron-circle-down"></i></span>
        </div>
      </div>
    </div>



    
    <div class="blockWrapper" v-if="showBlock3">
      <div class="statement mean">
        All inputs into <span style="color:red;">heedi</span> are completely anonymous.     
      </div>
      <div class="statement end">
        <span style="color:red;">heedi</span> only knows you as a random id: "0oTXJhEukOMRySY8XWgC".
      </div>
      <div class="statement end">
        We don't ask for an email address; no IP address is logged.  
      </div>
      <div class="statement">
        All we care about is that you are a human, acting purposely.
      </div>

      <div class="moreLink" @click="showBlock4 = !showBlock4" v-if="!showBlock4">
        <div v-if="!showBlock4">
          more&nbsp;<span class="downIcon"><i class="fas fa-chevron-circle-down"></i></span>
        </div>
      </div>
    </div>

   
    <div class="blockWrapper" v-if="showBlock4">
      <div class="statement mean">
        You start by <router-link :to="`/evolve/start-purpose`"><a href="#">declaring a <i>specific</i> purpose</a></router-link>
      </div>
      <div class="statement end">
        <span style="color:red;">heedi</span> will stay with you; promote your intentions back at you
      </div>
      <div class="statement end">
        Individually, together, we will build a knowledge graph of human purpose.  
      </div>
      
      <div class="statement">
        This knowledge of human purpose will then get embedded into the pencil
      </div>
      <div class="statement">
        And we will talk to this new pencil; have a conversation with it about our purposes in life
      </div>
      <div class="statement">
        And throughout the talk, we'll write down the next steps we determine are best
      </div>
      <div class="statement">
        Based on all those who have come before
      </div>
      
      <div class="moreLink" @click="showBlock4 = !showBlock4" v-if="!showBlock4">
        <div v-if="!showBlock4">
          more&nbsp;<span class="downIcon"><i class="fas fa-chevron-circle-down"></i></span>
        </div>
      </div>
    
    </div>
    
    
    <br />
  
    <br /><br />
   
    <br />
  
    <br />
    <MevChains v-on:load-finished="showFooter"/>
    <VueFooter v-if="showFooterBool"/>
-->

</template>

<script>
  import '@/plugins/vuetify'
  import signinModule from '@/store/signin'
  import userModule from '@/store/user'
  // import imageModule from '@/store/image'
  import searchModule from '@/store/search'
  import settingsModule from '@/store/settings'
  
  import RoutineSearch from '@/components/RoutineSearch'
  //import MomentMaker from '@/components/MomentMaker'
  // import MevChains from '@/components/MevChains'
  import VueFooter from '@/components/VueFooter'
  // import RoutineSearch from '@/components/RoutineSearch'
  // import SortableItem from '@/components/SortableItem'
  // import SortableList from '@/components/SortableList'
  //import ActionChains from '@/components/ActionChains'
  //import MomentHeedar from '@/components/MomentHeedar'
  //import feedbackModule from '@/store/feedback'
  // import experienceModule from '@/store/experience'
  import { mapActions } from 'vuex'
  //import draggable from 'vuedraggable'
  // import NestedDraggable2 from "@/components/nested/NestedDraggable2";
  // import RawDisplayer from "@/components/nested/RawDisplayer";

  let id = 1;

  export default {
    name: "about",
    order: 16,
    components: {
      //MomentMaker,
      RoutineSearch,
      // MevChains,
      VueFooter,
      //MomentHeedar,
      // SortableItem,
      // SortableList,
      // NestedDraggable2,
      // RawDisplayer,


    },
    data () {
      return {
        overlay: false,
        showFooterBool: false,
        heroImageSrc: "",
        items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6', 'Item 7', 'Item 8'],


        



        list: [
          {
            name: "task 1",
            tasks: [
              {
                name: "task 2",
                tasks: []
              }
            ]
          },
          {
            name: "task 3",
            tasks: [
              {
                name: "task 4",
                tasks: []
              }
            ]
          },
          {
            name: "task 5",
            tasks: []
          }
        ],
      

        showBlock2: false,
        showBlock3: false,
        showBlock4: false,

        //vuedraggable variables
        enabled: true,
        dragging: false,
        /*
        list: [
          { name: "John", id: 0 },
          { name: "Joao", id: 1 },
          { name: "Jean", id: 2 }
        ],
        */
      }
    },
    computed: {
      draggingInfo() {
        return this.dragging ? "under drag" : "";
      },
      elements: {
          get() {
            return this.$store.state.nested.elements;
          },
          set(value) {
            this.$store.commit("nested/updateElements", value, {root: true});
          }
        },
      
    },
    methods: {
      ...mapActions('signin', [
        'autoSignIn'
      ]),
      ...mapActions('image', [
        'getHeroImage'
      ]),
      ...mapActions('search', [
        'setShowSearch',
        'setShowSearchIcon'
      ]),
      showFooter () {
        this.showFooterBool = true;
      },
      add: function() {
      this.list.push({ name: "Juan " + id, id: id++ });
      },
      replace: function() {
        this.list = [{ name: "Edgard", id: id++ }];
      },
      checkMove: function(e) {
        window.console.log("Future index: " + e.draggedContext.futureIndex);
      }
    },
    watch: {

    },
    async beforeCreate () {
      //MUST HAVE ALL THREE OF THESE MODULES LOADED FOR AUTOSIGNIN TO WORK
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
      if(!this.$store.state.search) this.$store.registerModule('search', searchModule)
      //if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
      //if(!this.$store.state.nested) this.$store.registerModule('nested', nestedModule)
  
    },
    created () {
      /* eslint-disable no-undef */
      gtag('config', 'G-H9RD8W4MLR', {'page_path': '/support'});
      this.autoSignIn()
      this.setShowSearchIcon(true)
    },
    mounted () {
    },
    updated () {

    }
  }
</script>

<style scoped>
  .about {
    min-height: 400px;
  }

  .blockWrapper {
    background-color: white;
    padding: 30px;
    
    margin: 0 auto;
  }



  .searchContainer {
    text-align: center;

  }


  


  .moreLink {
    display: block;
    width: 30%;
    max-width: 200px;
    border: 2px solid #999;
    padding: 3px;
    margin: 0 auto;
    text-align: center;
    margin-top: 50px;
    color: #555;
    border-radius: 10px;
    background-color: #ddd;
    
  }
  .moreLink:hover {
    background-color: #000;
    color: white;
  }

  .downIcon {
    color: #777;
  }


  .buttons {
    margin-top: 35px;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .aboutContent {
    font-family: 'Quicksand', sans-serif;
    font-size: 1.3em;
    font-weight: 400;
  }

  .searchContainer {
    text-align: center;
    font-size: .7em;

  }

  #heediSupportImg {
    width: 90%;
  }
  .flexContainer {
    display: flex;
    flex-direction: column;
  }
  .heediImgHolder {
    padding: 25px;
    text-align: center;
  }

  @media screen and (min-width: 400px) {

    #heediSupportImg {
      width: 240px;
    }

  }

  @media screen and (min-width: 510px) {


    .flexContainer {
      flex-direction: row;
      text-align: left;
    }
  }


</style>